var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "edit-code-rates",
        title: "Edit practice fee rates",
        "hide-footer": "",
        size: "xl",
      },
      on: { hide: _vm.resetModal },
    },
    [
      _c(
        "b-form",
        { on: { submit: _vm.onSubmit } },
        [
          _c(
            "b-alert",
            {
              attrs: { variant: "danger", dismissible: "" },
              model: {
                value: _vm.showAlert,
                callback: function ($$v) {
                  _vm.showAlert = $$v
                },
                expression: "showAlert",
              },
            },
            [_c("b", [_vm._v(_vm._s(_vm.alertMessage))])]
          ),
          _c(
            "b-row",
            { attrs: { "align-h": "center" } },
            [
              _c(
                "b-col",
                { attrs: { cols: "9" } },
                [
                  _c(
                    "b-card",
                    {
                      attrs: { "bg-variant": "light", "text-variant": "dark" },
                    },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c("b-card-text", [
                                _c("b", [_vm._v("Code:")]),
                                _vm._v(
                                  " " + _vm._s(_vm.code.cpt_code || "N/A")
                                ),
                              ]),
                              _c("b-card-text", [
                                _c("b", [_vm._v("Description:")]),
                                _vm._v(" " + _vm._s(_vm.code.short || "N/A")),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            [
                              _c("b-card-text", [
                                _c("b", [_vm._v("Modifiers:")]),
                                _vm._v(
                                  " " + _vm._s(_vm.code.modifiers || "N/A")
                                ),
                              ]),
                              _c("b-card-text", [
                                _c("b", [_vm._v("POS/TOS:")]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.code.pos || "N/A") +
                                    ", " +
                                    _vm._s(_vm.code.tos || "N/A")
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mb-4" },
            [
              _c(
                "b-card",
                { staticClass: "mt-2 mb-2" },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "rates-master",
                        label: "Practice fee rates",
                        description:
                          "To edit payer-specific rates, view/edit payer rates and select a payer",
                        "label-size": "lg",
                        "label-cols-lg": "3",
                      },
                    },
                    [
                      _c(
                        "b-form-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "charge-fee",
                                    label: "Charge fee:",
                                  },
                                },
                                [
                                  _c(
                                    "b-input-group",
                                    { attrs: { prepend: "$" } },
                                    [
                                      _c("b-form-input", {
                                        attrs: { type: "number", required: "" },
                                        model: {
                                          value: _vm.form.charge_fee,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "charge_fee",
                                              $$v
                                            )
                                          },
                                          expression: "form.charge_fee",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "medicare-fee",
                                    label: "Medicare fee:",
                                  },
                                },
                                [
                                  _c(
                                    "b-input-group",
                                    { attrs: { prepend: "$" } },
                                    [
                                      _c("b-form-input", {
                                        attrs: { type: "number", required: "" },
                                        model: {
                                          value: _vm.form.medicare_fee,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "medicare_fee",
                                              $$v
                                            )
                                          },
                                          expression: "form.medicare_fee",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-form-group",
                                { attrs: { id: "cost", label: "Cost:" } },
                                [
                                  _c(
                                    "b-input-group",
                                    { attrs: { prepend: "$" } },
                                    [
                                      _c("b-form-input", {
                                        attrs: { type: "number", required: "" },
                                        model: {
                                          value: _vm.form.cost,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.form, "cost", $$v)
                                          },
                                          expression: "form.cost",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "self-pay-fee",
                                    label: "Self pay fee:",
                                  },
                                },
                                [
                                  _c(
                                    "b-input-group",
                                    { attrs: { prepend: "$" } },
                                    [
                                      _c("b-form-input", {
                                        attrs: { type: "number", required: "" },
                                        model: {
                                          value: _vm.form.self_pay_fee,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "self_pay_fee",
                                              $$v
                                            )
                                          },
                                          expression: "form.self_pay_fee",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-form-group",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.show_surgery_center_fields,
                          expression: "show_surgery_center_fields",
                        },
                      ],
                      attrs: {
                        id: "rates-surgery-center",
                        label: "Rates for Surgery Centers",
                        "label-size": "lg",
                        "label-cols-lg": "3",
                      },
                    },
                    [
                      _c(
                        "b-form-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "medicare-fee-surgery",
                                    label: "Medicare fee (surgery center):",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _vm._v(" Medicare fee: "),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "badge badge-primary",
                                            },
                                            [_vm._v("Surgery Center")]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                },
                                [
                                  _c(
                                    "b-input-group",
                                    { attrs: { prepend: "$" } },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          type: "number",
                                          required:
                                            _vm.show_surgery_center_fields,
                                        },
                                        model: {
                                          value:
                                            _vm.form
                                              .medicare_fee_surgery_center,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "medicare_fee_surgery_center",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.medicare_fee_surgery_center",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "cost-surgery",
                                    label: "Cost (surgery center):",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _vm._v(" Cost: "),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "badge badge-primary",
                                            },
                                            [_vm._v("Surgery Center")]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                },
                                [
                                  _c(
                                    "b-input-group",
                                    { attrs: { prepend: "$" } },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          type: "number",
                                          required:
                                            _vm.show_surgery_center_fields,
                                        },
                                        model: {
                                          value: _vm.form.cost_surgery_center,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "cost_surgery_center",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.cost_surgery_center",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-form-group",
                                {
                                  attrs: {
                                    id: "self-pay-fee-surgery",
                                    label: "Self pay fee (surgery center):",
                                  },
                                  scopedSlots: _vm._u([
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _vm._v(" Self pay fee: "),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "badge badge-primary",
                                            },
                                            [_vm._v("Surgery Center")]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ]),
                                },
                                [
                                  _c(
                                    "b-input-group",
                                    { attrs: { prepend: "$" } },
                                    [
                                      _c("b-form-input", {
                                        attrs: {
                                          type: "number",
                                          required:
                                            _vm.show_surgery_center_fields,
                                        },
                                        model: {
                                          value:
                                            _vm.form
                                              .self_pay_fee_surgery_center,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "self_pay_fee_surgery_center",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "form.self_pay_fee_surgery_center",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticStyle: { "text-align": "right mb-2" } },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              attrs: {
                                type: "checkbox",
                                size: "md",
                                id: "show_surgery_checkbox",
                                switch: "",
                              },
                              model: {
                                value: _vm.show_surgery_center_fields,
                                callback: function ($$v) {
                                  _vm.show_surgery_center_fields = $$v
                                },
                                expression: "show_surgery_center_fields",
                              },
                            },
                            [
                              _vm._v(" Edit "),
                              _c(
                                "span",
                                {
                                  class: _vm.show_surgery_center_fields
                                    ? "badge badge-primary"
                                    : "badge badge-secondary",
                                },
                                [_vm._v("Surgery Center")]
                              ),
                              _vm._v(" fields "),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-card",
                { staticClass: "mt-2 mb-2" },
                [
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        id: "underpayment-group",
                        label: "Claim auto-flagging",
                        "label-size": "lg",
                        "label-cols-lg": "3",
                      },
                    },
                    [
                      _c(
                        "b-form-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "4" } },
                            [
                              _c(
                                "b-form-group",
                                {
                                  staticStyle: { "text-align": "left" },
                                  attrs: {
                                    id: "underpayment-pct",
                                    label: "Underpayment percentage:",
                                  },
                                },
                                [
                                  _c(
                                    "b-input-group",
                                    { attrs: { append: "%" } },
                                    [
                                      _c("b-form-input", {
                                        attrs: { type: "number" },
                                        model: {
                                          value: _vm.form.underpayment,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.form,
                                              "underpayment",
                                              $$v
                                            )
                                          },
                                          expression: "form.underpayment",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticStyle: { "text-align": "right" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "avo-primary-btn mt-2",
                          on: { click: _vm.onSubmit },
                        },
                        [
                          _vm._v(" Save new rates "),
                          _c("b-icon", {
                            attrs: { icon: "arrow-down-right-square" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }