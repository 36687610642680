var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "edit-code-payers",
        title: "Payer rates",
        "hide-footer": "",
        size: "xl",
      },
      on: { hide: _vm.resetModal, hidden: _vm.onModalHidden },
    },
    [
      _c(
        "b-form",
        { on: { submit: _vm.onSubmit } },
        [
          _c(
            "b-alert",
            {
              attrs: { variant: "danger", dismissible: "" },
              model: {
                value: _vm.showAlert,
                callback: function ($$v) {
                  _vm.showAlert = $$v
                },
                expression: "showAlert",
              },
            },
            [_c("b", [_vm._v(_vm._s(_vm.alertMessage))])]
          ),
          _c(
            "b-row",
            { attrs: { "align-h": "center" } },
            [
              _c(
                "b-col",
                { attrs: { cols: "9" } },
                [
                  _c(
                    "b-card",
                    {
                      attrs: { "bg-variant": "light", "text-variant": "dark" },
                    },
                    [
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            [
                              _c("b-card-text", [
                                _c("b", [_vm._v("Code:")]),
                                _vm._v(
                                  " " + _vm._s(_vm.code.cpt_code || "N/A")
                                ),
                              ]),
                              _c("b-card-text", [
                                _c("b", [_vm._v("Description:")]),
                                _vm._v(" " + _vm._s(_vm.code.short || "N/A")),
                              ]),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            [
                              _c("b-card-text", [
                                _c("b", [_vm._v("Modifiers:")]),
                                _vm._v(
                                  " " + _vm._s(_vm.code.modifiers || "N/A")
                                ),
                              ]),
                              _c("b-card-text", [
                                _c("b", [_vm._v("POS/TOS:")]),
                                _vm._v(
                                  " " +
                                    _vm._s(_vm.code.pos || "N/A") +
                                    ", " +
                                    _vm._s(_vm.code.tos || "N/A")
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.staged_payers.length <= 0
            ? _c(
                "div",
                [
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.new-payers-modal",
                          modifiers: { "new-payers-modal": true },
                        },
                      ],
                      staticClass: "avo-primary-btn mt-4",
                    },
                    [_vm._v("Add more payers")]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.staged_payers.length > 0
            ? _c(
                "div",
                [
                  _c(
                    "b-card",
                    { staticClass: "mt-4" },
                    [
                      _c("h5", [_vm._v("New payers")]),
                      _c(
                        "b-table-simple",
                        { staticClass: "mt-1", attrs: { striped: "" } },
                        [
                          _c(
                            "b-thead",
                            [
                              _c(
                                "b-tr",
                                [
                                  _c("b-th"),
                                  _c("b-th", [_vm._v("Payer")]),
                                  _c("b-th", [_vm._v("Charge Fee")]),
                                  _c("b-th", [_vm._v("Contracted Fee")]),
                                  _c("b-th", [_vm._v("Allowed Amount")]),
                                  _vm.show_surgery_center_fields
                                    ? _c("b-th", [
                                        _vm._v("Charge Fee "),
                                        _c(
                                          "span",
                                          {
                                            class:
                                              _vm.show_surgery_center_fields
                                                ? "badge badge-primary"
                                                : "badge badge-secondary",
                                          },
                                          [_vm._v("Surgery Center")]
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.show_surgery_center_fields
                                    ? _c("b-th", [
                                        _vm._v("Contracted Fee "),
                                        _c(
                                          "span",
                                          {
                                            class:
                                              _vm.show_surgery_center_fields
                                                ? "badge badge-primary"
                                                : "badge badge-secondary",
                                          },
                                          [_vm._v("Surgery Center")]
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.show_surgery_center_fields
                                    ? _c("b-th", [
                                        _vm._v("Allowed Amount "),
                                        _c(
                                          "span",
                                          {
                                            class:
                                              _vm.show_surgery_center_fields
                                                ? "badge badge-primary"
                                                : "badge badge-secondary",
                                          },
                                          [_vm._v("Surgery Center")]
                                        ),
                                      ])
                                    : _vm._e(),
                                  _c("b-th"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-tbody",
                            _vm._l(
                              _vm.staged_payers,
                              function (payer, payer_index) {
                                return _c(
                                  "b-tr",
                                  { key: "new" + payer_index },
                                  [
                                    _c(
                                      "b-td",
                                      [
                                        _c("b-icon", {
                                          staticClass: "mb-2",
                                          staticStyle: { color: "#3455eb" },
                                          attrs: {
                                            icon: "dot",
                                            "font-scale": "1.25",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("b-td", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            payer.payer_object
                                              ? payer.payer_object.name
                                              : _vm.none || "N/A"
                                          ) +
                                          " "
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "avo-text-light" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              payer.payer_object
                                                ? payer.payer_object
                                                    .full_address
                                                : _vm.none || "No address"
                                            )
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("b-td", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            payer.charge_fee
                                              ? "$" + payer.charge_fee
                                              : "N/A"
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _c("b-td", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            payer.contracted_fee
                                              ? "$" + payer.contracted_fee
                                              : "N/A"
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _c("b-td", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            payer.allowed_amount
                                              ? "$" + payer.allowed_amount
                                              : "N/A"
                                          ) +
                                          " "
                                      ),
                                    ]),
                                    _vm.show_surgery_center_fields
                                      ? _c("b-td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                payer.charge_fee_surgery_center
                                                  ? "$" +
                                                      payer.charge_fee_surgery_center
                                                  : "N/A"
                                              ) +
                                              " "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.show_surgery_center_fields
                                      ? _c("b-td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                payer.contracted_fee_surgery_center
                                                  ? "$" +
                                                      payer.contracted_fee_surgery_center
                                                  : "N/A"
                                              ) +
                                              " "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.show_surgery_center_fields
                                      ? _c("b-td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                payer.allowed_amount_surgery_center
                                                  ? "$" +
                                                      payer.allowed_amount_surgery_center
                                                  : "N/A"
                                              ) +
                                              " "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _c(
                                      "b-td",
                                      [
                                        _c("b-icon", {
                                          staticClass: "mb-2",
                                          staticStyle: {
                                            color: "var(--gray-800)",
                                            cursor: "pointer",
                                          },
                                          attrs: {
                                            icon: "x-circle",
                                            "font-scale": "1.25",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removePayerRow(
                                                payer_index
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        { staticClass: "mt-1" },
                        [
                          _c(
                            "b-col",
                            [
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-modal",
                                      rawName: "v-b-modal.new-payers-modal",
                                      modifiers: { "new-payers-modal": true },
                                    },
                                  ],
                                  staticClass: "avo-primary-btn",
                                },
                                [_vm._v("Stage more payers")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { staticStyle: { "text-align": "right" } },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "avo-primary-btn",
                                  on: { click: _vm.onSubmit },
                                },
                                [
                                  _vm._v(" Save new payers "),
                                  _c("b-icon", {
                                    attrs: { icon: "arrow-down-right-square" },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _c("b-overlay", { attrs: { show: !_vm.payerRatesLoaded } }, [
            _vm.old_payers.length > 0
              ? _c(
                  "div",
                  [
                    _c(
                      "b-table-simple",
                      { staticClass: "mt-4", attrs: { striped: "" } },
                      [
                        _c(
                          "b-thead",
                          [
                            _c(
                              "b-tr",
                              [
                                _c("b-th"),
                                _c("b-th", [_vm._v("Payer")]),
                                _c("b-th", [_vm._v("Charge Fee")]),
                                _c("b-th", [_vm._v("Contracted Fee")]),
                                _c("b-th", [_vm._v("Allowed Amount")]),
                                _vm.show_surgery_center_fields
                                  ? _c("b-th", [
                                      _vm._v("Charge Fee "),
                                      _c(
                                        "span",
                                        {
                                          class: _vm.show_surgery_center_fields
                                            ? "badge badge-primary"
                                            : "badge badge-secondary",
                                        },
                                        [_vm._v("Surgery Center")]
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm.show_surgery_center_fields
                                  ? _c("b-th", [
                                      _vm._v("Contracted Fee "),
                                      _c(
                                        "span",
                                        {
                                          class: _vm.show_surgery_center_fields
                                            ? "badge badge-primary"
                                            : "badge badge-secondary",
                                        },
                                        [_vm._v("Surgery Center")]
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm.show_surgery_center_fields
                                  ? _c("b-th", [
                                      _vm._v("Allowed Amount "),
                                      _c(
                                        "span",
                                        {
                                          class: _vm.show_surgery_center_fields
                                            ? "badge badge-primary"
                                            : "badge badge-secondary",
                                        },
                                        [_vm._v("Surgery Center")]
                                      ),
                                    ])
                                  : _vm._e(),
                                _c("b-th"),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-tbody",
                          _vm._l(_vm.old_payers, function (payer, payer_index) {
                            return _c(
                              "b-tr",
                              { key: payer_index },
                              [
                                _c("b-td"),
                                _c("b-td", [
                                  _vm._v(
                                    " " + _vm._s(payer.name || "N/A") + " "
                                  ),
                                  _c("div", { staticClass: "avo-text-light" }, [
                                    _vm._v(
                                      _vm._s(payer.full_address || "No address")
                                    ),
                                  ]),
                                ]),
                                _c("b-td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        payer.charge_fee
                                          ? "$" + payer.charge_fee
                                          : "N/A"
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("b-td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        payer.contracted_fee
                                          ? "$" + payer.contracted_fee
                                          : "N/A"
                                      ) +
                                      " "
                                  ),
                                ]),
                                _c("b-td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        payer.allowed_amount
                                          ? "$" + payer.allowed_amount
                                          : "N/A"
                                      ) +
                                      " "
                                  ),
                                ]),
                                _vm.show_surgery_center_fields
                                  ? _c("b-td", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            payer.charge_fee_surgery_center
                                              ? "$" +
                                                  payer.charge_fee_surgery_center
                                              : "N/A"
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm.show_surgery_center_fields
                                  ? _c("b-td", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            payer.contracted_fee_surgery_center
                                              ? "$" +
                                                  payer.contracted_fee_surgery_center
                                              : "N/A"
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm.show_surgery_center_fields
                                  ? _c("b-td", [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            payer.allowed_amount_surgery_center
                                              ? "$" +
                                                  payer.allowed_amount_surgery_center
                                              : "N/A"
                                          ) +
                                          " "
                                      ),
                                    ])
                                  : _vm._e(),
                                _c(
                                  "b-td",
                                  [
                                    _c(
                                      "b-dropdown",
                                      {
                                        staticClass: "avo-action-dropdown",
                                        staticStyle: { "min-width": "none" },
                                        attrs: { "no-caret": "", right: true },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "button-content",
                                              fn: function () {
                                                return [
                                                  _c("b-icon", {
                                                    attrs: {
                                                      icon: "three-dots",
                                                    },
                                                  }),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c(
                                          "b-dropdown-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.viewEditPayerRatesModal(
                                                  payer,
                                                  payer_index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("b-icon", {
                                              staticClass: "mr-2",
                                              attrs: {
                                                variant: "secondary",
                                                icon: "pencil",
                                              },
                                            }),
                                            _vm._v(" Edit payer rates"),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-dropdown-item",
                                          {
                                            on: {
                                              click: function ($event) {
                                                return _vm.viewDeletePayerRatesModal(
                                                  payer_index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("b-icon", {
                                              staticClass: "mr-2",
                                              attrs: {
                                                variant: "secondary",
                                                icon: "trash",
                                              },
                                            }),
                                            _vm._v(" Remove payer from code"),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                    _c(
                                      "b-modal",
                                      {
                                        staticClass: "modal",
                                        attrs: {
                                          id:
                                            "remove-payer-rates-modal-" +
                                            payer_index,
                                          "hide-footer": "",
                                          "hide-header": "",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "avo-header-text mt-2 mb-4 bold",
                                            staticStyle: {
                                              "text-align": "center",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              " Remove fee details for " +
                                                _vm._s(payer.name) +
                                                "? "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "avo-text-light mb-4",
                                          },
                                          [
                                            _vm._v(
                                              " You will lose all fee rates associated with " +
                                                _vm._s(payer.name) +
                                                " for CPT code " +
                                                _vm._s(_vm.cpt_code) +
                                                " in " +
                                                _vm._s(_vm.year) +
                                                ". This cannot be undone. "
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticStyle: {
                                              "text-align": "right",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-button-group",
                                              [
                                                _c(
                                                  "b-button",
                                                  {
                                                    staticClass:
                                                      "avo-basic-btn ml-2",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.hideDeletePayerRatesModal(
                                                          payer_index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [_vm._v("Cancel")]
                                                ),
                                                _c(
                                                  "b-button",
                                                  {
                                                    staticClass:
                                                      "avo-primary-btn ml-2",
                                                    attrs: {
                                                      variant: "primary",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.deletePayerRates(
                                                          payer_index,
                                                          payer.payer_id,
                                                          _vm.cpt_code,
                                                          _vm.year
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Yes, delete fee details"
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "b-form-checkbox",
                      {
                        attrs: {
                          type: "checkbox",
                          size: "md",
                          id: "show_surgery_checkbox",
                          switch: "",
                        },
                        model: {
                          value: _vm.show_surgery_center_fields,
                          callback: function ($$v) {
                            _vm.show_surgery_center_fields = $$v
                          },
                          expression: "show_surgery_center_fields",
                        },
                      },
                      [
                        _vm._v(" Show "),
                        _c(
                          "span",
                          {
                            class: _vm.show_surgery_center_fields
                              ? "badge badge-primary"
                              : "badge badge-secondary",
                          },
                          [_vm._v("Surgery Center")]
                        ),
                        _vm._v(" fields "),
                      ]
                    ),
                  ],
                  1
                )
              : _c(
                  "div",
                  {
                    staticClass: "mt-4",
                    staticStyle: { "text-align": "center" },
                  },
                  [_vm._v(" No payers on file ")]
                ),
          ]),
          _c(
            "b-modal",
            {
              attrs: {
                id: "new-payers-modal",
                title: "Add payers",
                "hide-footer": "",
                size: "lg",
              },
            },
            [
              _vm._l(_vm.new_payers, function (payer, index) {
                return _c(
                  "div",
                  { key: "payer-" + index },
                  [
                    _c(
                      "b-card",
                      { staticClass: "mt-2 mb-2" },
                      [
                        _c(
                          "b-row",
                          [
                            _c(
                              "b-col",
                              { staticStyle: { "text-align": "left" } },
                              [_c("h5", [_vm._v("Payer " + _vm._s(index + 1))])]
                            ),
                            _c(
                              "b-col",
                              { staticStyle: { "text-align": "right" } },
                              [
                                _c("b-icon", {
                                  staticClass: "mb-2",
                                  staticStyle: {
                                    color: "var(--gray-800)",
                                    cursor: "pointer",
                                  },
                                  attrs: {
                                    icon: "x-circle",
                                    "font-scale": "1.25",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeNewPayerRow(index)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-form-row",
                          [
                            _c(
                              "b-col",
                              [
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "payer",
                                      label: "Payer:",
                                      "label-size": "lg",
                                      "label-cols-lg": "3",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-input-group",
                                      [
                                        _c("payer-multiselect", {
                                          model: {
                                            value: payer.payer_object,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                payer,
                                                "payer_object",
                                                $$v
                                              )
                                            },
                                            expression: "payer.payer_object",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            attrs: {
                              id: "rates-master",
                              label: "Rates:",
                              "label-size": "lg",
                              "label-cols-lg": "3",
                            },
                          },
                          [
                            _c(
                              "b-form-row",
                              [
                                _c(
                                  "b-col",
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          id: "charge-fee",
                                          label: "Charge fee:",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-input-group",
                                          { attrs: { prepend: "$" } },
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "number",
                                                required: "",
                                              },
                                              model: {
                                                value: payer.charge_fee,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    payer,
                                                    "charge_fee",
                                                    $$v
                                                  )
                                                },
                                                expression: "payer.charge_fee",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          id: "contracted-fee",
                                          label: "Contracted fee:",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-input-group",
                                          { attrs: { prepend: "$" } },
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "number",
                                                required: "",
                                              },
                                              model: {
                                                value: payer.contracted_fee,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    payer,
                                                    "contracted_fee",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "payer.contracted_fee",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          id: "allowed-amount",
                                          label: "Allowed amount:",
                                        },
                                      },
                                      [
                                        _c(
                                          "b-input-group",
                                          { attrs: { prepend: "$" } },
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "number",
                                                required: "",
                                              },
                                              model: {
                                                value: payer.allowed_amount,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    payer,
                                                    "allowed_amount",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "payer.allowed_amount",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-form-group",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.show_surgery_center_fields,
                                expression: "show_surgery_center_fields",
                              },
                            ],
                            attrs: {
                              id: "rates-surgery-center",
                              label: "Rates for surgery centers:",
                              "label-size": "lg",
                              "label-cols-lg": "3",
                            },
                          },
                          [
                            _c(
                              "b-form-row",
                              [
                                _c(
                                  "b-col",
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          id: "charge-fee-surgery-center",
                                          label: "Charge fee:",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "label",
                                              fn: function () {
                                                return [
                                                  _vm._v(" Charge fee: "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "badge badge-primary",
                                                    },
                                                    [_vm._v("Surgery Center")]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c(
                                          "b-input-group",
                                          { attrs: { prepend: "$" } },
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "number",
                                                required:
                                                  _vm.show_surgery_center_fields,
                                              },
                                              model: {
                                                value:
                                                  payer.charge_fee_surgery_center,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    payer,
                                                    "charge_fee_surgery_center",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "payer.charge_fee_surgery_center",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          id: "contracted-fee-surgery-center",
                                          label: "Contracted fee:",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "label",
                                              fn: function () {
                                                return [
                                                  _vm._v(" Contracted fee: "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "badge badge-primary",
                                                    },
                                                    [_vm._v("Surgery Center")]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c(
                                          "b-input-group",
                                          { attrs: { prepend: "$" } },
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "number",
                                                required:
                                                  _vm.show_surgery_center_fields,
                                              },
                                              model: {
                                                value:
                                                  payer.contracted_fee_surgery_center,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    payer,
                                                    "contracted_fee_surgery_center",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "payer.contracted_fee_surgery_center",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  [
                                    _c(
                                      "b-form-group",
                                      {
                                        attrs: {
                                          id: "allowed-amount-surgery-center",
                                          label: "Allowed amount:",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "label",
                                              fn: function () {
                                                return [
                                                  _vm._v(" Allowed amount: "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "badge badge-primary",
                                                    },
                                                    [_vm._v("Surgery Center")]
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c(
                                          "b-input-group",
                                          { attrs: { prepend: "$" } },
                                          [
                                            _c("b-form-input", {
                                              attrs: {
                                                type: "number",
                                                required:
                                                  _vm.show_surgery_center_fields,
                                              },
                                              model: {
                                                value:
                                                  payer.allowed_amount_surgery_center,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    payer,
                                                    "allowed_amount_surgery_center",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "payer.allowed_amount_surgery_center",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "b-form-checkbox",
                          {
                            attrs: {
                              type: "checkbox",
                              size: "md",
                              id: "show_surgery_checkbox",
                              switch: "",
                            },
                            model: {
                              value: _vm.show_surgery_center_fields,
                              callback: function ($$v) {
                                _vm.show_surgery_center_fields = $$v
                              },
                              expression: "show_surgery_center_fields",
                            },
                          },
                          [
                            _vm._v(" Show "),
                            _c(
                              "span",
                              {
                                class: _vm.show_surgery_center_fields
                                  ? "badge badge-primary"
                                  : "badge badge-secondary",
                              },
                              [_vm._v("Surgery Center")]
                            ),
                            _vm._v(" fields "),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
              _c(
                "b-row",
                [
                  _c(
                    "b-col",
                    { staticStyle: { "text-align": "left" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "avo-primary-btn mt-2",
                          on: { click: _vm.addNewPayer },
                        },
                        [
                          _c("b-icon", { attrs: { icon: "plus-circle" } }),
                          _vm._v(" Add another payer "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { staticStyle: { "text-align": "right" } },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "avo-primary-btn mt-2",
                          on: { click: _vm.stageNewPayers },
                        },
                        [
                          _c("b-icon", { attrs: { icon: "clipboard-plus" } }),
                          _vm._v(" Stage payer details "),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _vm.selectedPayer
        ? _c(
            "b-modal",
            {
              attrs: {
                id: "edit-payer-rates",
                title: "Edit rates for " + _vm.selectedPayer.name,
                "hide-footer": "",
                size: "lg",
              },
            },
            [
              _c(
                "b-form",
                [
                  _c(
                    "b-card",
                    { staticClass: "mt-2 mb-2" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "rates-master",
                            label: "Rates:",
                            "label-size": "lg",
                            "label-cols-lg": "3",
                          },
                        },
                        [
                          _c(
                            "b-form-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        id: "charge-fee",
                                        label: "Charge fee:",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        { attrs: { prepend: "$" } },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              type: "number",
                                              required: "",
                                            },
                                            model: {
                                              value: _vm.payer.charge_fee,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.payer,
                                                  "charge_fee",
                                                  $$v
                                                )
                                              },
                                              expression: "payer.charge_fee",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        id: "contracted-fee",
                                        label: "Contracted fee:",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        { attrs: { prepend: "$" } },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              type: "number",
                                              required: "",
                                            },
                                            model: {
                                              value: _vm.payer.contracted_fee,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.payer,
                                                  "contracted_fee",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "payer.contracted_fee",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        id: "allowed-amount",
                                        label: "Allowed amount:",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        { attrs: { prepend: "$" } },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              type: "number",
                                              required: "",
                                            },
                                            model: {
                                              value: _vm.payer.allowed_amount,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.payer,
                                                  "allowed_amount",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "payer.allowed_amount",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.show_surgery_center_fields,
                              expression: "show_surgery_center_fields",
                            },
                          ],
                          attrs: {
                            id: "rates-surgery-center",
                            label: "Rates for surgery centers:",
                            "label-size": "lg",
                            "label-cols-lg": "3",
                          },
                        },
                        [
                          _c(
                            "b-form-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        id: "charge-fee-surgery-center",
                                        label: "Charge fee:",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "label",
                                            fn: function () {
                                              return [
                                                _vm._v(" Charge fee: "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "badge badge-primary",
                                                  },
                                                  [_vm._v("Surgery Center")]
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        2639785523
                                      ),
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        { attrs: { prepend: "$" } },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              type: "number",
                                              required:
                                                _vm.show_surgery_center_fields,
                                            },
                                            model: {
                                              value:
                                                _vm.payer
                                                  .charge_fee_surgery_center,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.payer,
                                                  "charge_fee_surgery_center",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "payer.charge_fee_surgery_center",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        id: "contracted-fee-surgery-center",
                                        label: "Contracted fee:",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "label",
                                            fn: function () {
                                              return [
                                                _vm._v(" Contracted fee: "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "badge badge-primary",
                                                  },
                                                  [_vm._v("Surgery Center")]
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        1114107226
                                      ),
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        { attrs: { prepend: "$" } },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              type: "number",
                                              required:
                                                _vm.show_surgery_center_fields,
                                            },
                                            model: {
                                              value:
                                                _vm.payer
                                                  .contracted_fee_surgery_center,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.payer,
                                                  "contracted_fee_surgery_center",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "payer.contracted_fee_surgery_center",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        id: "allowed-amount-surgery-center",
                                        label: "Allowed amount:",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "label",
                                            fn: function () {
                                              return [
                                                _vm._v(" Allowed amount: "),
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "badge badge-primary",
                                                  },
                                                  [_vm._v("Surgery Center")]
                                                ),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        1997582267
                                      ),
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        { attrs: { prepend: "$" } },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              type: "number",
                                              required:
                                                _vm.show_surgery_center_fields,
                                            },
                                            model: {
                                              value:
                                                _vm.payer
                                                  .allowed_amount_surgery_center,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.payer,
                                                  "allowed_amount_surgery_center",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "payer.allowed_amount_surgery_center",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { staticStyle: { "text-align": "right mb-2" } },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: {
                                    type: "checkbox",
                                    size: "md",
                                    id: "show_surgery_checkbox",
                                    switch: "",
                                  },
                                  model: {
                                    value: _vm.show_surgery_center_fields,
                                    callback: function ($$v) {
                                      _vm.show_surgery_center_fields = $$v
                                    },
                                    expression: "show_surgery_center_fields",
                                  },
                                },
                                [
                                  _vm._v(" Edit "),
                                  _c(
                                    "span",
                                    {
                                      class: _vm.show_surgery_center_fields
                                        ? "badge badge-primary"
                                        : "badge badge-secondary",
                                    },
                                    [_vm._v("Surgery Center")]
                                  ),
                                  _vm._v(" fields "),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticStyle: { "text-align": "right" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "avo-primary-btn mt-2",
                              on: { click: _vm.updatePayerRates },
                            },
                            [
                              _vm._v(" Save new rates "),
                              _c("b-icon", {
                                attrs: { icon: "arrow-down-right-square" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }