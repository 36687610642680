var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: {
        id: "add-new-code",
        title: "Add a new code",
        "hide-footer": "",
        size: "xl",
      },
      on: { hide: _vm.resetModal },
    },
    [
      _c(
        "b-nav",
        { staticClass: "mb-4 nav-section", attrs: { tabs: "" } },
        [
          _c(
            "b-nav-item",
            {
              attrs: { active: !_vm.do_show_payer_info_tab },
              on: {
                click: function ($event) {
                  return _vm.setPayerInfo(false)
                },
              },
            },
            [_vm._v("Step 1: Code information")]
          ),
          _c(
            "b-nav-item",
            {
              attrs: { active: _vm.do_show_payer_info_tab },
              on: {
                click: function ($event) {
                  return _vm.setPayerInfo(true)
                },
              },
            },
            [_vm._v("Step 2: Payer information")]
          ),
        ],
        1
      ),
      _c(
        "b-form",
        { on: { submit: _vm.onSubmit } },
        [
          _c(
            "b-alert",
            {
              attrs: { variant: "danger", dismissible: "" },
              model: {
                value: _vm.showAlert,
                callback: function ($$v) {
                  _vm.showAlert = $$v
                },
                expression: "showAlert",
              },
            },
            [_c("b", [_vm._v(_vm._s(_vm.alertMessage))])]
          ),
          _vm.do_show_payer_info_tab
            ? _c(
                "div",
                [
                  _c(
                    "b-row",
                    { attrs: { "align-h": "center" } },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "9" } },
                        [
                          _c(
                            "b-card",
                            {
                              attrs: {
                                "bg-variant": "light",
                                "text-variant": "dark",
                              },
                            },
                            [
                              _c(
                                "b-row",
                                [
                                  _c(
                                    "b-col",
                                    [
                                      _c("b-card-text", [
                                        _c("b", [_vm._v("Code:")]),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.form.cpt_code
                                                ? _vm.form.cpt_code.code
                                                : null || "N/A"
                                            ) +
                                            ", "
                                        ),
                                        _c("b", [_vm._v("Modifiers:")]),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.formatModifiers(
                                                _vm.form.modifiers
                                              ) || "N/A"
                                            )
                                        ),
                                      ]),
                                      _c("b-card-text", [
                                        _c("b", [_vm._v("POS/TOS:")]),
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.form.pos || "N/A") +
                                            ", " +
                                            _vm._s(_vm.form.tos || "N/A")
                                        ),
                                      ]),
                                      _c("b-card-text", [
                                        _c("b", [_vm._v("Description:")]),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.form.cpt_code
                                                ? _vm.form.cpt_code.short
                                                : null || "N/A"
                                            )
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-col",
                                    [
                                      _c("b-card-text", [
                                        _c("b", [_vm._v("Charge fee:")]),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.form.charge_fee
                                                ? "$" + _vm.form.charge_fee
                                                : "N/A"
                                            )
                                        ),
                                      ]),
                                      _c("b-card-text", [
                                        _c("b", [_vm._v("Medicare:")]),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.form.medicare_fee
                                                ? "$" + _vm.form.medicare_fee
                                                : "N/A"
                                            )
                                        ),
                                      ]),
                                      _c("b-card-text", [
                                        _c("b", [_vm._v("Cost:")]),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.form.cost
                                                ? "$" + _vm.form.cost
                                                : "N/A"
                                            )
                                        ),
                                      ]),
                                      _c("b-card-text", [
                                        _c("b", [_vm._v("Self-pay:")]),
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.form.self_pay_fee
                                                ? "$" + _vm.form.self_pay_fee
                                                : "N/A"
                                            )
                                        ),
                                      ]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.payers.length > 0
                    ? _c(
                        "div",
                        [
                          _c(
                            "b-table-simple",
                            { staticClass: "mt-4", attrs: { striped: "" } },
                            [
                              _c(
                                "b-thead",
                                [
                                  _c(
                                    "b-tr",
                                    [
                                      _c("b-th", [_vm._v("Payer")]),
                                      _c("b-th", [_vm._v("Charge Fee")]),
                                      _c("b-th", [_vm._v("Contracted Fee")]),
                                      _c("b-th", [_vm._v("Allowed Amount")]),
                                      _c("b-th"),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-tbody",
                                _vm._l(
                                  _vm.payers,
                                  function (payer, payer_index) {
                                    return _c(
                                      "b-tr",
                                      { key: payer_index },
                                      [
                                        _c("b-td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                payer.payer_object
                                                  ? payer.payer_object.name
                                                  : null || "N/A"
                                              ) +
                                              " "
                                          ),
                                          _c(
                                            "span",
                                            { staticClass: "avo-text-light" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  payer.payer_object
                                                    ? " - " +
                                                        payer.payer_object
                                                          .full_address
                                                    : null || ""
                                                )
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _c("b-td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                payer.charge_fee
                                                  ? "$" + payer.charge_fee
                                                  : "N/A"
                                              ) +
                                              " "
                                          ),
                                        ]),
                                        _c("b-td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                payer.contracted_fee
                                                  ? "$" + payer.contracted_fee
                                                  : "N/A"
                                              ) +
                                              " "
                                          ),
                                        ]),
                                        _c("b-td", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                payer.allowed_amount
                                                  ? "$" + payer.allowed_amount
                                                  : "N/A"
                                              ) +
                                              " "
                                          ),
                                        ]),
                                        _c(
                                          "b-td",
                                          [
                                            _c("b-icon", {
                                              staticClass: "mb-2",
                                              staticStyle: {
                                                color: "var(--gray-800)",
                                                cursor: "pointer",
                                              },
                                              attrs: {
                                                icon: "x-circle",
                                                "font-scale": "1.25",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.removePayerRow(
                                                    payer_index
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        {
                          staticClass: "mt-4",
                          staticStyle: { "text-align": "center" },
                        },
                        [_vm._v(" No payers added ")]
                      ),
                  _c(
                    "b-button",
                    {
                      directives: [
                        {
                          name: "b-modal",
                          rawName: "v-b-modal.new-payers-modal",
                          modifiers: { "new-payers-modal": true },
                        },
                      ],
                      staticClass: "avo-primary-btn",
                    },
                    [_vm._v("Add payers")]
                  ),
                  _c(
                    "b-modal",
                    {
                      attrs: {
                        id: "new-payers-modal",
                        title: "Add payers",
                        "hide-footer": "",
                        size: "lg",
                      },
                    },
                    [
                      _vm._l(_vm.new_payers, function (payer, index) {
                        return _c(
                          "div",
                          { key: "payer-" + index },
                          [
                            _c(
                              "b-card",
                              { staticClass: "mt-2 mb-2" },
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c(
                                      "b-col",
                                      { staticStyle: { "text-align": "left" } },
                                      [
                                        _c("h5", [
                                          _vm._v("Payer " + _vm._s(index + 1)),
                                        ]),
                                      ]
                                    ),
                                    _c(
                                      "b-col",
                                      {
                                        staticStyle: { "text-align": "right" },
                                      },
                                      [
                                        _c("b-icon", {
                                          staticClass: "mb-2",
                                          staticStyle: {
                                            color: "var(--gray-800)",
                                            cursor: "pointer",
                                          },
                                          attrs: {
                                            icon: "x-circle",
                                            "font-scale": "1.25",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.removeNewPayerRow(
                                                index
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-form-row",
                                  [
                                    _c(
                                      "b-col",
                                      [
                                        _c(
                                          "b-form-group",
                                          {
                                            attrs: {
                                              id: "payer",
                                              label: "Payer:",
                                              "label-size": "lg",
                                              "label-cols-lg": "3",
                                            },
                                          },
                                          [
                                            _c(
                                              "b-input-group",
                                              [
                                                _c("payer-multiselect", {
                                                  model: {
                                                    value: payer.payer_object,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        payer,
                                                        "payer_object",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "payer.payer_object",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-form-group",
                                  {
                                    attrs: {
                                      id: "rates-master",
                                      label: "Rates:",
                                      "label-size": "lg",
                                      "label-cols-lg": "3",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-form-row",
                                      [
                                        _c(
                                          "b-col",
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  id: "charge-fee",
                                                  label: "Charge fee:",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-input-group",
                                                  { attrs: { prepend: "$" } },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "number",
                                                        required: "",
                                                      },
                                                      model: {
                                                        value: payer.charge_fee,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            payer,
                                                            "charge_fee",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "payer.charge_fee",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-col",
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  id: "contracted-fee",
                                                  label: "Contracted fee:",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-input-group",
                                                  { attrs: { prepend: "$" } },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "number",
                                                        required: "",
                                                      },
                                                      model: {
                                                        value:
                                                          payer.contracted_fee,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            payer,
                                                            "contracted_fee",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "payer.contracted_fee",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-col",
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  id: "allowed-amount",
                                                  label: "Allowed amount:",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-input-group",
                                                  { attrs: { prepend: "$" } },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "number",
                                                        required: "",
                                                      },
                                                      model: {
                                                        value:
                                                          payer.allowed_amount,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            payer,
                                                            "allowed_amount",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "payer.allowed_amount",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-form-group",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.show_surgery_center_fields,
                                        expression:
                                          "show_surgery_center_fields",
                                      },
                                    ],
                                    attrs: {
                                      id: "rates-surgery-center",
                                      label: "Rates for surgery centers:",
                                      "label-size": "lg",
                                      "label-cols-lg": "3",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-form-row",
                                      [
                                        _c(
                                          "b-col",
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  id: "charge-fee-surgery-center",
                                                  label: "Charge fee:",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _vm._v(
                                                            " Charge fee: "
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "badge badge-primary",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Surgery Center"
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _c(
                                                  "b-input-group",
                                                  { attrs: { prepend: "$" } },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "number",
                                                        required:
                                                          _vm.show_surgery_center_fields,
                                                      },
                                                      model: {
                                                        value:
                                                          payer.charge_fee_surgery_center,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            payer,
                                                            "charge_fee_surgery_center",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "payer.charge_fee_surgery_center",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-col",
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  id: "contracted-fee-surgery-center",
                                                  label: "Contracted fee:",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _vm._v(
                                                            " Contracted fee: "
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "badge badge-primary",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Surgery Center"
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _c(
                                                  "b-input-group",
                                                  { attrs: { prepend: "$" } },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "number",
                                                        required:
                                                          _vm.show_surgery_center_fields,
                                                      },
                                                      model: {
                                                        value:
                                                          payer.contracted_fee_surgery_center,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            payer,
                                                            "contracted_fee_surgery_center",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "payer.contracted_fee_surgery_center",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "b-col",
                                          [
                                            _c(
                                              "b-form-group",
                                              {
                                                attrs: {
                                                  id: "allowed-amount-surgery-center",
                                                  label: "Allowed amount:",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "label",
                                                      fn: function () {
                                                        return [
                                                          _vm._v(
                                                            " Allowed amount: "
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "badge badge-primary",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "Surgery Center"
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              },
                                              [
                                                _c(
                                                  "b-input-group",
                                                  { attrs: { prepend: "$" } },
                                                  [
                                                    _c("b-form-input", {
                                                      attrs: {
                                                        type: "number",
                                                        required:
                                                          _vm.show_surgery_center_fields,
                                                      },
                                                      model: {
                                                        value:
                                                          payer.allowed_amount_surgery_center,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            payer,
                                                            "allowed_amount_surgery_center",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "payer.allowed_amount_surgery_center",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { staticStyle: { "text-align": "left" } },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "avo-primary-btn mt-2",
                                  on: { click: _vm.addNewPayer },
                                },
                                [
                                  _c("b-icon", {
                                    attrs: { icon: "plus-circle" },
                                  }),
                                  _vm._v(" Add another payer "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            { staticStyle: { "text-align": "right" } },
                            [
                              _c(
                                "b-button",
                                {
                                  staticClass: "avo-primary-btn mt-2",
                                  on: { click: _vm.commitNewPayers },
                                },
                                [
                                  _c("b-icon", {
                                    attrs: { icon: "clipboard-plus" },
                                  }),
                                  _vm._v(" Stage payer details "),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticStyle: { "text-align": "right" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "avo-primary-btn mt-2",
                              on: { click: _vm.onSubmit },
                            },
                            [
                              _vm._v(" Finalize and save "),
                              _c("b-icon", {
                                attrs: { icon: "arrow-down-right-square" },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "mb-4" },
                [
                  _c(
                    "b-card",
                    { staticClass: "mt-2 mb-2" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "code-info",
                            label: "Code details",
                            "label-size": "lg",
                            "label-cols-lg": "3",
                          },
                        },
                        [
                          _c(
                            "b-form-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        id: "cpt_code",
                                        label: "CPT Code:",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        [
                                          _c(
                                            "multiselect",
                                            {
                                              attrs: {
                                                openDirection: "bottom",
                                                placeholder:
                                                  "Search by code or description",
                                                label: "code",
                                                "custom-label":
                                                  _vm.codeWithDescription,
                                                "track-by": "code",
                                                options: _vm.selectableCPTCodes,
                                                loading: _vm.cptLoading,
                                                "internal-search": false,
                                                "show-labels": false,
                                                showNoOptions: true,
                                                "allow-empty": false,
                                              },
                                              on: {
                                                "search-change":
                                                  _vm.debounceCPTCodes,
                                              },
                                              model: {
                                                value: _vm.form.cpt_code,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.form,
                                                    "cpt_code",
                                                    $$v
                                                  )
                                                },
                                                expression: "form.cpt_code",
                                              },
                                            },
                                            [
                                              _c(
                                                "template",
                                                { slot: "noResult" },
                                                [
                                                  _vm._v(
                                                    " No codes matched your search "
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "template",
                                                { slot: "noOptions" },
                                                [
                                                  _vm._v(
                                                    " Start typing a code or description... "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            2
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        id: "modifiers",
                                        label: "Modifiers:",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        [
                                          _c("b-form-input", {
                                            staticClass: "mr-2",
                                            attrs: {
                                              id: "modifier-1",
                                              type: "text",
                                            },
                                            model: {
                                              value: _vm.form.modifiers[0],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.modifiers,
                                                  0,
                                                  $$v
                                                )
                                              },
                                              expression: "form.modifiers[0]",
                                            },
                                          }),
                                          _c("b-form-input", {
                                            staticClass: "mr-2",
                                            attrs: {
                                              id: "modifier-2",
                                              type: "text",
                                            },
                                            model: {
                                              value: _vm.form.modifiers[1],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.modifiers,
                                                  1,
                                                  $$v
                                                )
                                              },
                                              expression: "form.modifiers[1]",
                                            },
                                          }),
                                          _c("b-form-input", {
                                            staticClass: "mr-2",
                                            attrs: {
                                              id: "modifier-3",
                                              type: "text",
                                            },
                                            model: {
                                              value: _vm.form.modifiers[2],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.modifiers,
                                                  2,
                                                  $$v
                                                )
                                              },
                                              expression: "form.modifiers[2]",
                                            },
                                          }),
                                          _c("b-form-input", {
                                            attrs: {
                                              id: "modifier-4",
                                              type: "text",
                                            },
                                            model: {
                                              value: _vm.form.modifiers[3],
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form.modifiers,
                                                  3,
                                                  $$v
                                                )
                                              },
                                              expression: "form.modifiers[3]",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    { attrs: { id: "pos", label: "POS:" } },
                                    [
                                      _c(
                                        "b-input-group",
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              type: "number",
                                              required: "",
                                            },
                                            model: {
                                              value: _vm.form.pos,
                                              callback: function ($$v) {
                                                _vm.$set(_vm.form, "pos", $$v)
                                              },
                                              expression: "form.pos",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    { attrs: { id: "tos", label: "TOS:" } },
                                    [
                                      _c(
                                        "b-input-group",
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              type: "number",
                                              required: "",
                                            },
                                            model: {
                                              value: _vm.form.tos,
                                              callback: function ($$v) {
                                                _vm.$set(_vm.form, "tos", $$v)
                                              },
                                              expression: "form.tos",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-card",
                    { staticClass: "mt-2 mb-2" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "rates-master",
                            label: "Practice fee rates",
                            "label-size": "lg",
                            "label-cols-lg": "3",
                          },
                        },
                        [
                          _c(
                            "b-form-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        id: "charge-fee",
                                        label: "Charge fee:",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        { attrs: { prepend: "$" } },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              type: "number",
                                              required: "",
                                            },
                                            model: {
                                              value: _vm.form.charge_fee,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "charge_fee",
                                                  $$v
                                                )
                                              },
                                              expression: "form.charge_fee",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        id: "medicare-fee",
                                        label: "Medicare fee:",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        { attrs: { prepend: "$" } },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              type: "number",
                                              required: "",
                                            },
                                            model: {
                                              value: _vm.form.medicare_fee,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "medicare_fee",
                                                  $$v
                                                )
                                              },
                                              expression: "form.medicare_fee",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    { attrs: { id: "cost", label: "Cost:" } },
                                    [
                                      _c(
                                        "b-input-group",
                                        { attrs: { prepend: "$" } },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              type: "number",
                                              required: "",
                                            },
                                            model: {
                                              value: _vm.form.cost,
                                              callback: function ($$v) {
                                                _vm.$set(_vm.form, "cost", $$v)
                                              },
                                              expression: "form.cost",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        id: "self-pay-fee",
                                        label: "Self pay fee:",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        { attrs: { prepend: "$" } },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              type: "number",
                                              required: "",
                                            },
                                            model: {
                                              value: _vm.form.self_pay_fee,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "self_pay_fee",
                                                  $$v
                                                )
                                              },
                                              expression: "form.self_pay_fee",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-form-group",
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.show_surgery_center_fields,
                              expression: "show_surgery_center_fields",
                            },
                          ],
                          attrs: {
                            id: "rates-surgery-center",
                            label: "Rates for Surgery Centers",
                            "label-size": "lg",
                            "label-cols-lg": "3",
                          },
                        },
                        [
                          _c(
                            "b-form-row",
                            [
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        id: "medicare-fee-surgery",
                                        label: "Medicare fee (surgery center):",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _vm._v(" Medicare fee: "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "badge badge-primary",
                                                },
                                                [_vm._v("Surgery Center")]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        { attrs: { prepend: "$" } },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              type: "number",
                                              required:
                                                _vm.show_surgery_center_fields,
                                            },
                                            model: {
                                              value:
                                                _vm.form
                                                  .medicare_fee_surgery_center,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "medicare_fee_surgery_center",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.medicare_fee_surgery_center",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        id: "cost-surgery",
                                        label: "Cost (surgery center):",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _vm._v(" Cost: "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "badge badge-primary",
                                                },
                                                [_vm._v("Surgery Center")]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        { attrs: { prepend: "$" } },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              type: "number",
                                              required:
                                                _vm.show_surgery_center_fields,
                                            },
                                            model: {
                                              value:
                                                _vm.form.cost_surgery_center,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "cost_surgery_center",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.cost_surgery_center",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        id: "self-pay-fee-surgery",
                                        label: "Self pay fee (surgery center):",
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "label",
                                          fn: function () {
                                            return [
                                              _vm._v(" Self pay fee: "),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "badge badge-primary",
                                                },
                                                [_vm._v("Surgery Center")]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ]),
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        { attrs: { prepend: "$" } },
                                        [
                                          _c("b-form-input", {
                                            attrs: {
                                              type: "number",
                                              required:
                                                _vm.show_surgery_center_fields,
                                            },
                                            model: {
                                              value:
                                                _vm.form
                                                  .self_pay_fee_surgery_center,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "self_pay_fee_surgery_center",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "form.self_pay_fee_surgery_center",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { staticStyle: { "text-align": "right mb-2" } },
                            [
                              _c(
                                "b-form-checkbox",
                                {
                                  attrs: {
                                    type: "checkbox",
                                    size: "md",
                                    id: "show_surgery_checkbox",
                                    switch: "",
                                  },
                                  model: {
                                    value: _vm.show_surgery_center_fields,
                                    callback: function ($$v) {
                                      _vm.show_surgery_center_fields = $$v
                                    },
                                    expression: "show_surgery_center_fields",
                                  },
                                },
                                [
                                  _vm._v(" Add "),
                                  _c(
                                    "span",
                                    {
                                      class: _vm.show_surgery_center_fields
                                        ? "badge badge-primary"
                                        : "badge badge-secondary",
                                    },
                                    [_vm._v("Surgery Center")]
                                  ),
                                  _vm._v(" fields "),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-card",
                    { staticClass: "mt-2 mb-2" },
                    [
                      _c(
                        "b-form-group",
                        {
                          attrs: {
                            id: "underpayment-group",
                            label: "Claim auto-flagging",
                            "label-size": "lg",
                            "label-cols-lg": "3",
                          },
                        },
                        [
                          _c(
                            "b-form-row",
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      staticStyle: { "text-align": "left" },
                                      attrs: {
                                        id: "underpayment-pct",
                                        label: "Underpayment percentage:",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-input-group",
                                        { attrs: { append: "%" } },
                                        [
                                          _c("b-form-input", {
                                            attrs: { type: "number" },
                                            model: {
                                              value: _vm.form.underpayment,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.form,
                                                  "underpayment",
                                                  $$v
                                                )
                                              },
                                              expression: "form.underpayment",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "b-row",
                    [
                      _c(
                        "b-col",
                        { staticStyle: { "text-align": "right" } },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "avo-primary-btn",
                              on: {
                                click: function ($event) {
                                  return _vm.setPayerInfo(true)
                                },
                              },
                            },
                            [_vm._v(" Continue to add payer rates ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }