var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.practice || _vm.practiceId
    ? _c(
        "div",
        [
          _c(
            "b-alert",
            {
              staticStyle: { "margin-left": "20px" },
              attrs: { variant: "danger", dismissible: "" },
              model: {
                value: _vm.showAlert,
                callback: function ($$v) {
                  _vm.showAlert = $$v
                },
                expression: "showAlert",
              },
            },
            [_c("b", [_vm._v(_vm._s(_vm.alertMessage))])]
          ),
          _c(
            "b-row",
            [
              _c(
                "b-col",
                {
                  staticStyle: { "margin-left": "20px" },
                  attrs: { cols: "3" },
                },
                [
                  _c("multiselect", {
                    attrs: {
                      "show-labels": false,
                      "hide-selected": false,
                      "close-on-select": true,
                      "track-by": "name",
                      "custom-label": _vm.payerLabelWithAddress,
                      options: _vm.filters.options,
                      multiple: false,
                      internalSearch: true,
                      placeholder: "-- Select a Payer  --",
                    },
                    on: { close: _vm.getFees },
                    model: {
                      value: _vm.filters.payer,
                      callback: function ($$v) {
                        _vm.$set(_vm.filters, "payer", $$v)
                      },
                      expression: "filters.payer",
                    },
                  }),
                ],
                1
              ),
              _c(
                "b-col",
                [
                  _c(
                    "b-input-group",
                    { staticClass: "search-group" },
                    [
                      _c("b-input-group-prepend", [
                        _c(
                          "span",
                          { staticClass: "input-group-text" },
                          [_c("b-icon", { attrs: { icon: "search" } })],
                          1
                        ),
                      ]),
                      _c("b-form-input", {
                        staticClass: "fee-search-input pl-1",
                        attrs: {
                          type: "search",
                          placeholder: "Filter fee schedule by code",
                        },
                        on: {
                          keyup: _vm.debounceFees,
                          search: _vm.debounceFees,
                        },
                        model: {
                          value: _vm.search,
                          callback: function ($$v) {
                            _vm.search = $$v
                          },
                          expression: "search",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("b-col", [
                _c(
                  "div",
                  {
                    style: {
                      visibility:
                        _vm.filters.payer || _vm.search ? "visible" : "hidden",
                    },
                  },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { variant: "outline-danger" },
                        on: { click: _vm.clearFilters },
                      },
                      [_vm._v("Clear Filters")]
                    ),
                  ],
                  1
                ),
              ]),
              _c(
                "b-col",
                [
                  _c(
                    "b-button-group",
                    [
                      _c(
                        "b-button",
                        {
                          class:
                            "avo-basic-btn mr-2 " +
                            (_vm.selectedTab == "master"
                              ? "not-collapsed"
                              : ""),
                          attrs: { variant: "outline-primary" },
                          on: {
                            click: function ($event) {
                              return _vm.selectTab("master")
                            },
                          },
                        },
                        [_vm._v(" Master ")]
                      ),
                      _c(
                        "b-button",
                        {
                          class:
                            "avo-basic-btn mr-2 " +
                            (_vm.selectedTab == "surgery-center"
                              ? "not-collapsed"
                              : ""),
                          attrs: { variant: "outline-primary" },
                          on: {
                            click: function ($event) {
                              return _vm.selectTab("surgery-center")
                            },
                          },
                        },
                        [_vm._v(" Surgery Center ")]
                      ),
                      _c(
                        "b-button",
                        {
                          class:
                            "avo-basic-btn mr-2 " +
                            (_vm.selectedTab == "self-pay"
                              ? "not-collapsed"
                              : ""),
                          attrs: { variant: "outline-primary" },
                          on: {
                            click: function ($event) {
                              return _vm.selectTab("self-pay")
                            },
                          },
                        },
                        [_vm._v(" Self Pay ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("b-col", { attrs: { cols: "1" } }, [
                _c(
                  "div",
                  [
                    _c("multiselect", {
                      attrs: {
                        "show-labels": false,
                        "hide-selected": false,
                        "close-on-select": true,
                        "allow-empty": false,
                        "track-by": "year",
                        label: "year",
                        options: _vm.yearOptions,
                        multiple: false,
                        internalSearch: true,
                        placeholder: "-- Year --",
                      },
                      on: { close: _vm.getFees },
                      model: {
                        value: _vm.filters.year,
                        callback: function ($$v) {
                          _vm.$set(_vm.filters, "year", $$v)
                        },
                        expression: "filters.year",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _c("b-col", { staticClass: "col-auto" }, [
                _c(
                  "div",
                  { staticStyle: { "margin-right": "20px" } },
                  [
                    _vm.hasPermission("edit")
                      ? _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-modal",
                                rawName: "v-b-modal.add-new-code",
                                modifiers: { "add-new-code": true },
                              },
                            ],
                            staticClass: "ml-2 avo-primary-btn",
                          },
                          [_vm._v("Add new code")]
                        )
                      : _vm._e(),
                    _c("practice-fee-add", {
                      attrs: {
                        practiceId: _vm.practice_id,
                        year: _vm.currentYear,
                        payerInfoTab: false,
                      },
                      on: {
                        submitted: function ($event) {
                          return _vm.refreshTable()
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "b-overlay",
            {
              staticStyle: { "min-height": "250px" },
              attrs: { show: !_vm.loaded, variant: "white" },
            },
            [
              _vm.fees.length > 0
                ? _c(
                    "div",
                    {
                      staticStyle: {
                        "margin-left": "20px",
                        "margin-right": "20px",
                      },
                    },
                    [
                      _c(
                        "b-table-simple",
                        {
                          staticClass: "fee-table mt-4",
                          staticStyle: { "font-size": "14px" },
                          attrs: { borderless: "" },
                        },
                        [
                          _c(
                            "b-thead",
                            [
                              _c(
                                "b-tr",
                                [
                                  _c("b-th", [_vm._v(" Code ")]),
                                  _c("b-th", [_vm._v(" Description ")]),
                                  _c("b-th", [_vm._v(" Modifiers ")]),
                                  _c("b-th", [_vm._v(" POS ")]),
                                  _c("b-th", [_vm._v(" TOS ")]),
                                  _c("b-th", [
                                    _vm.selectedTab == "master" ||
                                    _vm.selectedTab == "self-pay"
                                      ? _c("div", [_vm._v(" Medicare Fee ")])
                                      : _vm._e(),
                                    _vm.selectedTab == "surgery-center"
                                      ? _c("div", [
                                          _vm._v(" Medicare Fee "),
                                          _c("br"),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "badge badge-primary",
                                            },
                                            [_vm._v("Surgery Center")]
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]),
                                  _vm.selectedTab == "master" ||
                                  _vm.selectedTab == "surgery-center"
                                    ? _c("b-th", [
                                        _vm.selectedTab == "master"
                                          ? _c("div", [_vm._v(" Charge Fee ")])
                                          : _vm._e(),
                                        _vm.selectedTab == "surgery-center"
                                          ? _c("div", [
                                              _vm._v(" Charge Fee "),
                                              _c("br"),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "badge badge-primary",
                                                },
                                                [_vm._v("Surgery Center")]
                                              ),
                                            ])
                                          : _vm._e(),
                                      ])
                                    : _vm._e(),
                                  _c("b-th", [
                                    _vm.selectedTab == "master"
                                      ? _c("div", [_vm._v(" Contracted Fee ")])
                                      : _vm._e(),
                                    _vm.selectedTab == "surgery-center"
                                      ? _c("div", [
                                          _vm._v(" Contracted Fee "),
                                          _c("br"),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "badge badge-primary",
                                            },
                                            [_vm._v("Surgery Center")]
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.selectedTab == "self-pay"
                                      ? _c("div", [_vm._v(" Self-pay Fee ")])
                                      : _vm._e(),
                                  ]),
                                  _c("b-th", [
                                    _vm.selectedTab == "master"
                                      ? _c("div", [_vm._v(" Allowed Amount ")])
                                      : _vm._e(),
                                    _vm.selectedTab == "surgery-center"
                                      ? _c("div", [
                                          _vm._v(" Allowed Amount "),
                                          _c("br"),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "badge badge-primary",
                                            },
                                            [_vm._v("Surgery Center")]
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm.selectedTab == "self-pay"
                                      ? _c("div", [
                                          _vm._v(" Self-pay Fee "),
                                          _c("br"),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "badge badge-primary",
                                            },
                                            [_vm._v("Surgery Center")]
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]),
                                  _c("b-th", [
                                    _vm.selectedTab == "master" ||
                                    _vm.selectedTab == "self-pay"
                                      ? _c("div", [_vm._v(" Cost ")])
                                      : _vm._e(),
                                    _vm.selectedTab == "surgery-center"
                                      ? _c("div", [
                                          _vm._v(" Cost "),
                                          _c("br"),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "badge badge-primary",
                                            },
                                            [_vm._v("Surgery Center")]
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]),
                                  _vm.selectedTab == "self-pay"
                                    ? _c("b-th", [
                                        _c("div", [
                                          _vm._v(" Cost "),
                                          _c("br"),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "badge badge-primary",
                                            },
                                            [_vm._v("Surgery Center")]
                                          ),
                                        ]),
                                      ])
                                    : _vm._e(),
                                  _c("b-th", [_vm._v(" Actions ")]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "b-tbody",
                            _vm._l(_vm.fees, function (fee, index) {
                              return _c(
                                "b-tr",
                                { key: index },
                                [
                                  _c("b-td", [
                                    _vm._v(
                                      " " + _vm._s(fee.cpt_code || "N/A") + " "
                                    ),
                                  ]),
                                  _c("b-td", [
                                    _vm._v(
                                      " " + _vm._s(fee.short || "N/A") + " "
                                    ),
                                  ]),
                                  _c("b-td", [
                                    _vm._v(
                                      " " + _vm._s(fee.modifiers || "N/A") + " "
                                    ),
                                  ]),
                                  _c("b-td", [
                                    _vm._v(
                                      " " + _vm._s(fee.pos || "N/A") + " "
                                    ),
                                  ]),
                                  _c("b-td", [
                                    _vm._v(
                                      " " + _vm._s(fee.tos || "N/A") + " "
                                    ),
                                  ]),
                                  _vm.selectedTab == "master" ||
                                  _vm.selectedTab == "self-pay"
                                    ? _c("b-td", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              fee.medicare_fee
                                                ? "$" + fee.medicare_fee
                                                : "N/A"
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.selectedTab == "surgery-center"
                                    ? _c("b-td", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              fee.medicare_fee_surgery_center
                                                ? "$" +
                                                    fee.medicare_fee_surgery_center
                                                : "N/A"
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.selectedTab == "master"
                                    ? _c(
                                        "b-td",
                                        {
                                          class: _vm.filters.payer
                                            ? ""
                                            : "avo-text-light",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                fee.charge_fee
                                                  ? "$" + fee.charge_fee
                                                  : _vm.filters.payer
                                                  ? "N/A"
                                                  : "-- Select a payer --"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.selectedTab == "surgery-center"
                                    ? _c(
                                        "b-td",
                                        {
                                          class: _vm.filters.payer
                                            ? ""
                                            : "avo-text-light",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                fee.charge_fee_surgery_center
                                                  ? "$" +
                                                      fee.charge_fee_surgery_center
                                                  : _vm.filters.payer
                                                  ? "N/A"
                                                  : "-- Select a payer --"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.selectedTab == "master"
                                    ? _c(
                                        "b-td",
                                        {
                                          class: _vm.filters.payer
                                            ? ""
                                            : "avo-text-light",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                fee.contracted_fee
                                                  ? "$" + fee.contracted_fee
                                                  : _vm.filters.payer
                                                  ? "N/A"
                                                  : "-- Select a payer --"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.selectedTab == "surgery-center"
                                    ? _c(
                                        "b-td",
                                        {
                                          class: _vm.filters.payer
                                            ? ""
                                            : "avo-text-light",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                fee.contracted_fee_surgery_center
                                                  ? "$" +
                                                      fee.contracted_fee_surgery_center
                                                  : _vm.filters.payer
                                                  ? "N/A"
                                                  : "-- Select a payer --"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.selectedTab == "master"
                                    ? _c(
                                        "b-td",
                                        {
                                          class: _vm.filters.payer
                                            ? ""
                                            : "avo-text-light",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                fee.allowed_amount
                                                  ? "$" + fee.allowed_amount
                                                  : _vm.filters.payer
                                                  ? "N/A"
                                                  : "-- Select a payer --"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.selectedTab == "surgery-center"
                                    ? _c(
                                        "b-td",
                                        {
                                          class: _vm.filters.payer
                                            ? ""
                                            : "avo-text-light",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                fee.allowed_amount_surgery_center
                                                  ? "$" +
                                                      fee.allowed_amount_surgery_center
                                                  : _vm.filters.payer
                                                  ? "N/A"
                                                  : "-- Select a payer --"
                                              ) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                  _vm.selectedTab == "self-pay"
                                    ? _c("b-td", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              fee.self_pay_fee
                                                ? "$" + fee.self_pay_fee
                                                : "N/A"
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.selectedTab == "self-pay"
                                    ? _c("b-td", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              fee.self_pay_fee_surgery_center
                                                ? "$" +
                                                    fee.self_pay_fee_surgery_center
                                                : "N/A"
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.selectedTab == "master" ||
                                  _vm.selectedTab == "self-pay"
                                    ? _c("b-td", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              fee.cost ? "$" + fee.cost : "N/A"
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.selectedTab == "surgery-center"
                                    ? _c("b-td", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              fee.cost_surgery_center
                                                ? "$" + fee.cost_surgery_center
                                                : "N/A"
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.selectedTab == "self-pay"
                                    ? _c("b-td", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              fee.cost_surgery_center
                                                ? "$" + fee.cost_surgery_center
                                                : "N/A"
                                            ) +
                                            " "
                                        ),
                                      ])
                                    : _vm._e(),
                                  _c("b-td", [
                                    _c(
                                      "div",
                                      [
                                        _c(
                                          "b-dropdown",
                                          {
                                            staticClass: "avo-action-dropdown",
                                            staticStyle: {
                                              "min-width": "none",
                                            },
                                            attrs: {
                                              "no-caret": "",
                                              right: true,
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "button-content",
                                                  fn: function () {
                                                    return [
                                                      _c("b-icon", {
                                                        attrs: {
                                                          icon: "three-dots",
                                                        },
                                                      }),
                                                    ]
                                                  },
                                                  proxy: true,
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c(
                                              "b-dropdown-item",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.viewEditCodePayersModal(
                                                      index,
                                                      fee
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("b-icon", {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    variant: "secondary",
                                                    icon: "credit-card",
                                                  },
                                                }),
                                                _vm._v(
                                                  " View/edit payer rates"
                                                ),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "b-dropdown-item",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.viewEditCodeRatesModal(
                                                      index,
                                                      fee
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("b-icon", {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    variant: "secondary",
                                                    icon: "percent",
                                                  },
                                                }),
                                                _vm._v(
                                                  " Edit practice fee rates"
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm.filters.payer
                                              ? _c(
                                                  "div",
                                                  [
                                                    _c(
                                                      "b-dropdown-item",
                                                      {
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.viewRemovePayerFromCodeModal(
                                                              index
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("b-icon", {
                                                          staticClass: "mr-2",
                                                          attrs: {
                                                            variant:
                                                              "secondary",
                                                            icon: "trash",
                                                          },
                                                        }),
                                                        _vm._v(
                                                          " Remove fees for " +
                                                            _vm._s(
                                                              _vm.filters.payer
                                                                .name
                                                            )
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _c(
                                              "b-dropdown-item",
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.viewRemoveCodeModal(
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("b-icon", {
                                                  staticClass: "mr-2",
                                                  attrs: {
                                                    variant: "secondary",
                                                    icon: "trash-fill",
                                                  },
                                                }),
                                                _vm._v(
                                                  " Remove code from practice"
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm.filters.payer
                                          ? _c(
                                              "b-modal",
                                              {
                                                staticClass: "modal",
                                                attrs: {
                                                  id:
                                                    "remove-payer-code-modal-" +
                                                    index,
                                                  "hide-footer": "",
                                                  "hide-header": "",
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-header-text mt-2 mb-4 bold",
                                                    staticStyle: {
                                                      "text-align": "center",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " Remove fee details for " +
                                                        _vm._s(
                                                          _vm.filters.payer.name
                                                        ) +
                                                        "? "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "avo-text-light mb-4",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " You will lose all fee rates associated with " +
                                                        _vm._s(
                                                          _vm.filters.payer.name
                                                        ) +
                                                        " for CPT code " +
                                                        _vm._s(fee.cpt_code) +
                                                        " in " +
                                                        _vm._s(
                                                          _vm.currentYear
                                                        ) +
                                                        ". This cannot be undone. "
                                                    ),
                                                  ]
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      "text-align": "right",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-button-group",
                                                      [
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass:
                                                              "avo-basic-btn ml-2",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.hideRemovePayerFromCodeModal(
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("Cancel")]
                                                        ),
                                                        _c(
                                                          "b-button",
                                                          {
                                                            staticClass:
                                                              "avo-primary-btn ml-2",
                                                            attrs: {
                                                              variant:
                                                                "primary",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.removePayerFromCode(
                                                                  index,
                                                                  _vm.filters
                                                                    .payer
                                                                    .payer_id,
                                                                  fee.cpt_code,
                                                                  _vm.currentYear
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Yes, delete fee details"
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c(
                                          "b-modal",
                                          {
                                            staticClass: "modal",
                                            attrs: {
                                              id: "remove-code-modal-" + index,
                                              "hide-footer": "",
                                              "hide-header": "",
                                            },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "avo-header-text mt-2 mb-4 bold",
                                                staticStyle: {
                                                  "text-align": "center",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " Remove all fee details for CPT code " +
                                                    _vm._s(fee.cpt_code) +
                                                    "? "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "avo-text-light mb-4",
                                              },
                                              [
                                                _vm._v(
                                                  " You will lose all fee rates associated with CPT code: " +
                                                    _vm._s(fee.cpt_code) +
                                                    " modifiers: " +
                                                    _vm._s(fee.modifiers) +
                                                    " tos/pos: " +
                                                    _vm._s(fee.tos) +
                                                    "/" +
                                                    _vm._s(fee.pos) +
                                                    " for " +
                                                    _vm._s(_vm.currentYear) +
                                                    ". This cannot be undone. "
                                                ),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  "text-align": "right",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-button-group",
                                                  [
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "avo-basic-btn ml-2",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.hideRemoveCodeModal(
                                                              index
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [_vm._v("Cancel")]
                                                    ),
                                                    _c(
                                                      "b-button",
                                                      {
                                                        staticClass:
                                                          "avo-primary-btn ml-2",
                                                        attrs: {
                                                          variant: "primary",
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.removeCode(
                                                              index,
                                                              fee.cpt_code,
                                                              fee.modifiers,
                                                              fee.tos,
                                                              fee.pos,
                                                              _vm.currentYear
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Yes, delete fee details"
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("b-pagination", {
                            attrs: {
                              "total-rows": _vm.totalRows,
                              "per-page": _vm.perPage,
                              align: "center",
                            },
                            model: {
                              value: _vm.page,
                              callback: function ($$v) {
                                _vm.page = $$v
                              },
                              expression: "page",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm.loaded
                ? _c("div", { staticStyle: { "text-align": "center" } }, [
                    _vm._v(" No fees match the selected filters "),
                  ])
                : _vm._e(),
            ]
          ),
          _vm.selectedFee
            ? _c("practice-fee-edit-rates", {
                key: "rates" + (_vm.selectedFeeIndex || 0),
                attrs: {
                  codeInfo: _vm.selectedFee,
                  practiceId: _vm.practice_id,
                  year: _vm.currentYear,
                },
                on: {
                  submitted: function ($event) {
                    return _vm.refreshTable()
                  },
                },
              })
            : _vm._e(),
          _vm.selectedFee
            ? _c("practice-fee-edit-payers", {
                key: "payer" + (_vm.selectedFeeIndex || 0),
                attrs: {
                  codeInfo: _vm.selectedFee,
                  practiceId: _vm.practice_id,
                  year: _vm.currentYear,
                },
                on: {
                  "modal-hidden": function ($event) {
                    return _vm.refreshTable()
                  },
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }